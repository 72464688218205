.file {
    &-actions {
        .ms-FocusZone {
            &:hover {
                background: none;
            }
        }
    }

    &-list {
        .ms-FocusZone {
            &:hover {
                background: none;
            }
        }
    }
}