@import "@fluentui/react/dist/sass/References";

.loader {
    &.huge {
        .ms-Spinner-circle {
            width: 150px;
            height: 150px;
            border-width: 4.5px;
        }

        .ms-Spinner-label {
            font-size: $ms-font-size-32;
        }
    }

    &.large {
        .ms-Spinner-circle {
            width: 100px;
            height: 100px;
            border-width: 3px;
        }

        .ms-Spinner-label {
            font-size: $ms-font-size-24;
        }
    }

    &.medium {
        .ms-Spinner-circle {
            width: 50px;
            height: 50px;
            border-width: 2px;
        }

        .ms-Spinner-label {
            font-size: $ms-font-size-16;
        }
    }

    &.small {
        .ms-Spinner-circle {
            width: 25px;
            height: 25px;
            border-width: 1.5px;
        }

        .ms-Spinner-label {
            font-size: $ms-font-size-14;
        }
    }
}